var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    [
      _c("el-row", [
        _c("div", { staticClass: "backtop" }, [
          _c("span", { on: { click: _vm.gotoBack } }, [
            _c(
              "i",
              {
                staticClass: "el-icon-arrow-left",
                staticStyle: { cursor: "pointer" },
              },
              [_vm._v("返回上一级")]
            ),
          ]),
        ]),
      ]),
      _c(
        "el-row",
        { staticClass: "table" },
        [
          _c(
            "el-table",
            {
              staticClass: "tb-list",
              staticStyle: { "margin-top": "30px" },
              attrs: {
                border: "",
                fit: "",
                "highlight-current-row": "",
                data: _vm.dataList,
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "提交人ID", align: "center", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.loginName))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "姓名", align: "center", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "所属问题ID", align: "center", width: "230" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.problem_id))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "所属问题标题",
                  align: "left",
                  "min-width": "450",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.problemName))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "结果", align: "center", width: "230" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            staticClass: "type-span",
                            style:
                              "backgroundColor" +
                              ":" +
                              _vm.getStateColor(scope.row.result),
                            on: {
                              click: function ($event) {
                                return _vm.subDetail(scope.row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.getSubType(scope.row.result)) +
                                " "
                            ),
                            scope.row.pass_rate && scope.row.pass_rate !== 1
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      (1 - Number(scope.row.pass_rate)).toFixed(
                                        1
                                      ) * 100
                                    ) + "%"
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "详情", align: "center", width: "230" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-row",
                          { staticStyle: { "line-height": "25px" } },
                          [
                            _c("i", { staticClass: "el-icon-time ico-time" }),
                            _c("span", [
                              _vm._v(_vm._s(scope.row.time) + " ms"),
                            ]),
                            _c("i", { staticClass: "el-icon-coin ico-bulk" }),
                            _c("span", [
                              _vm._v(_vm._s(scope.row.memory) + " KB"),
                            ]),
                            _c("br"),
                            _c(
                              "div",
                              { staticStyle: { "margin-left": "8px" } },
                              [
                                _c("i", { staticClass: "el-icon-document" }),
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "5px" } },
                                  [_vm._v(_vm._s(scope.row.code_length))]
                                ),
                                _vm._v(" B "),
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "5px" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.getLanText(scope.row.language))
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "提交时间", align: "center", width: "230" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("dateFormat")(scope.row.in_date))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }